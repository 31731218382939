import React from "react";

import '../styles.css';

const Privacy = () => {

    return (
        <>
        <div style={{ paddingBottom: "30px" }}>
            <div className="container_full">
                <div className="subtitle">Privacy Policy</div>
                
                <div className="heading">Introduction</div>
                <div className="text">
                    We value your privacy and strive to protect your personal information. This privacy policy outlines how we collect, use, disclose, and safeguard your information when you use our services. Please read this policy carefully. If you disagree with its terms, please discontinue use of our services.
                </div><br/>

                <div className="heading">Information We Collect</div>
                <div className="text">
                    We may collect information in the following ways:<br/>

                    • Directly from you: When you register, make a purchase, or interact with one of our services.<br/>
                    • Automatically: Through tracking technologies like cookies to understand a service's usage.
                </div><br/>

                <div className="heading">How We Use Your Information</div>
                <div className="text">
                    We may use your information for the following purposes:<br/>

                    • To facilitate the creation of and secure your account.<br/>
                    • To improve a service's functionality.<br/>
                    • To understand user preferences and tailor content accordingly.<br/>
                    • For marketing communications.
                </div><br/>

                <div className="heading">Sharing Your Information</div>
                <div className="text">
                    We do not sell or trade your personal information. We may share information with third parties when:<br/>

                    • You have given explicit consent.<br/>
                    • It's required to complete a transaction.<br/>
                    • It's mandated by law.
                </div><br/>

                <div className="heading">User Rights</div>
                <div className="text">
                    You have the right to:<br/>

                    • Request, modify, or delete your personal data.<br/>
                    • Opt-out of certain uses of your data.<br/>
                    • Lodge a complaint with regulatory authorities.
                </div><br/>

                <div className="heading">Data Security</div>
                <div className="text">
                    We take necessary precautions to secure your data. However, no transmission over the internet is 100% secure. We cannot guarantee complete security but promise to notify you in case of any data breach.
                </div><br/>

                <div className="heading">Third-Party Links</div>
                <div className="text">
                    Our services may contain links to third-party websites. This policy doesn't apply to those external sites. We advise you to read their privacy policies.
                </div><br/>

                <div className="heading">Changes to This Policy</div>
                <div className="text">
                    We may update this policy occasionally. We advise you to review this page periodically. Your continued use of our services post any modification will constitute acceptance.
                </div><br/>

                <div className="heading">Contact Us</div>
                <div className="text">
                    If you have questions about this policy, please contact us at help@808.social.
                </div>
            </div>
        </div>
        </>
    );
}

export default Privacy;