import React from "react";

import '../styles.css';

const Terms = () => {

    return (
        <>
        <div style={{ paddingBottom: "30px" }}>
            <div className="container_full">
                <div className="subtitle">Terms of use</div>
                
                <div className="heading">Acceptance of Terms</div>
                <div className="text">
                    By accessing or using services developed by Beluga Group, you agree to be bound by these terms and conditions. If you disagree with any part of these terms, please discontinue use of our services.
                </div><br/>

                <div className="heading">Registration and Account Security</div>
                <div className="text">
                    Users may be required to register for an account. Users agree to:<br/>

                    • Provide accurate, current, and complete information.<br/>
                    • Maintain the confidentiality of their account and password.<br/>
                    • Promptly notify us of any unauthorized use.
                </div><br/>

                <div className="heading">User Conduct</div>
                <div className="text">
                    Users must not:<br/>

                    • Use our services for illegal activities.<br/>
                    • Infringe on intellectual property rights.<br/>
                    • Transmit malware or viruses.<br/>
                </div><br/>

                <div className="heading">Termination of Account</div>
                <div className="text">
                    We reserve the right to terminate or suspend access to our services without prior notice for conduct that violates these terms or is harmful to other users, us, or third parties, or for any other reason we deem appropriate.
                </div><br/>

                <div className="heading">Intellectual Property</div>
                <div className="text">
                    All content, trademarks, and logos in the app are owned by or licensed to Beluga Group and are subject to copyright and other intellectual property rights.
                </div><br/>

                <div className="heading">Limitation of Liability</div>
                <div className="text">
                    Beluga Group shall not be liable for any direct, indirect, incidental, or consequential damages resulting from the use or inability to use our services.
                </div><br/>

                <div className="heading">Changes to These Terms</div>
                <div className="text">
                    We reserve the right to modify or replace these terms at any time. Your continued use of our services after changes constitute acceptance of the new terms.
                </div><br/>

                <div className="heading">Contact Us</div>
                <div className="text">
                    For any questions regarding these terms, please contact us at help@808.social.
                </div>
            </div>
        </div>
        </>
    );
}

export default Terms;