import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import Home from "./components/Home";
import Privacy from "./components/Privacy";
import Terms from "./components/Terms";

const App = () => {

  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<Home/>}/>
        <Route path="/privacy" exact element={<Privacy/>}/>
        <Route path="/terms" exact element={<Terms/>}/>
        <Route path="*" exact element={<Navigate to="/"/>}/>
      </Routes>
    
    </BrowserRouter>
    </>
  );
}

export default App;