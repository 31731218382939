import React from "react";

import '../styles.css';

const Home = () => {
    return (
        <>
        <div className="center_container">
            <div className="centered">
                <div className="container">
                    <div className="title">808</div>
                    
                    <div style={{ width: "90%", margin: "0 auto" }}>
                        <div className="text" style={{ fontSize: "20px" }}>Connect with people in your city,<br/>every night at 8:08 PM.</div>
                    </div>

                    <div style={{ width: "100%", marginTop: "27px", marginBottom: "3px" }}>
                        <img className="image" src={require('../images/lightspeed.png')} alt=""/>
                    </div>
                    
                    <a href="https://testflight.apple.com/join/nAtIdGDv" target="_blank" rel="noreferrer"><button type="button" className="button1" style={{ marginTop: "30px" }}>Download for iOS</button></a>
                </div>
            </div>
        </div>
        </>
    );
}

export default Home;